import React from 'react'
import {Link as AnchorLink} from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"

const windowIsDefined = (typeof window !== `undefined`)? true : false
if (windowIsDefined) {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Link = ({ children, to, activeClassName, partiallyActive, clickCallback, className, locationState}) => {
    var samePageLink = false;
    var hashInUrl = false;
    var destination = false;
    var pathnameWithoutHash = false;

    const customScroll = () => {
      setTimeout(function() {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      }, 500);
      clickCallback();
    }

    if (windowIsDefined) {
      hashInUrl = to.includes("#")
      destination = to.split('#')[0]
      pathnameWithoutHash = window.location.pathname
      samePageLink = false

      if(destination == pathnameWithoutHash) {
        samePageLink = true
      }
    }

    // Use Gatsby Link for internal links, and <a> for others
    if(hashInUrl && samePageLink){
      return (
        <AnchorLink state={{ locationState: locationState }} smooth-scroll="true" className={className + " custom-anchor"} onClick={clickCallback} to={to}>{children}</AnchorLink>
      )
    } else if (samePageLink){
      return (
        <AnchorLink state={{ locationState: locationState }} smooth-scroll="true" className={className + " custom-anchor"} onClick={customScroll} to={to}>{children}</AnchorLink>
      )
    } else {
      return (
        <AnchorLink
          className={className + " custom-link"}
          bg="#21a4de"
          to={to}
          activeClassName={activeClassName}
          state={{ locationState: locationState }}
        >
          {children}
        </AnchorLink>
      )
    }
}
export default Link