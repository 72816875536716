import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import AnimHeader from "../shared/animheader"

import "./specs_grid.scss"

import Specs from "../../data/specs.json"

var specImages = require.context('../../images/specs_thumbs', true)
var specPDFs = require.context('../../images/specs_pdfs', true)

export default class SpecsGrid extends Component {

  static propTypes = {
    specsType: PropTypes.string
  }

  static defaultProps = {
    specsType: 'floorplans'
  }

  constructor(props) {
    super(props);
    this.state = { specsType: this.props.specsType, specs: Specs }
  }

  componentDidMount() {
    this.specsGrid = React.createRef();
  }

  render() {
    const specGrid = this.state.specs.find(spec => (spec.id === this.props.specsType));
    return (
      <div className={`specs-grid ` + this.state.specsType} ref={this.specsGrid}>
        {specGrid.specs.map(spec => (
          <div key={spec.id} className="spec-grid-item">
            <a target="_blank" href={specPDFs(`./${spec.pdf_path}.pdf`)}>
              <div className="spec-grid-item-inner" style={{backgroundImage: 'url(' + specImages(`./${spec.thumbnail_path}.jpg`) + ')'}} >
              </div>
              <div className="download-spec"><span className="dl-link">Download PDF</span></div>
              <div className="spec-name">{spec.name}</div>
            </a>
          </div>
        ))}
      </div>
    )
  }
}
