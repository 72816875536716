import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import VisibilitySensor from 'react-visibility-sensor'

import LeasingIcon01 from "../../assets/leasing-logo-01.svg";
import LeasingIcon02 from "../../assets/leasing-logo-02.svg";
import LeasingIcon03 from "../../assets/leasing-logo-03.svg";
import LeasingIcon04 from "../../assets/leasing-logo-04.svg";
import LeasingIcon05 from "../../assets/leasing-logo-05.svg";
import LeasingIcon07 from "../../assets/leasing-logo-07.svg";
import LeasingIcon08 from "../../assets/leasing-logo-08.svg";
import LeasingIcon09 from "../../assets/leasing-logo-09.svg";
import LeasingIcon10 from "../../assets/leasing-logo-10.svg";
import LeasingIcon11 from "../../assets/leasing-logo-11.svg";
import LeasingIcon12 from "../../assets/leasing-logo-12.svg";
import LeasingIcon13 from "../../assets/leasing-logo-13.svg";
import LeasingIcon14 from "../../assets/leasing-logo-14.svg";
import LeasingIcon15 from "../../assets/leasing-logo-15.svg";
import LeasingIcon16 from "../../assets/leasing-logo-16.svg";

import AnimHeader from "../shared/animheader"

import "./leasing-grid.scss"

export default class LeasingGrid extends Component {

  constructor (props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="block-container">
        <div className="block-container-inner">
          <div className="leasing-grid-wrapper">
            <div className="leasing-grid-inner">
             <div className="leasing-grid-item">
                <LeasingIcon16 />
                <div className="grid-item-content">Outdoor space on every floor.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon10 />
                <div className="grid-item-content">Gender neutral restroom on all floors – exceeding code.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon07 />
                <div className="grid-item-content">10,000 SF fitness center.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon15 />
                <div className="grid-item-content">Three exit stairs allowing for up to 900 people on any floor.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon09 />
                <div className="grid-item-content">Restrooms on both sides of the core allowing for easy access.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon13 />
                <div className="grid-item-content">Two freight elevators serving all tenant floors.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon12 />
                <div className="grid-item-content">Next generation elevator design allows for no transfer floors.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon11 />
                <div className="grid-item-content">Drinking fountains with chilled water and bottle fill stations on both sides of the core.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon08 />
                <div className="grid-item-content">Planned community theatre on ground floor available for scheduled group events.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon03 />
                <div className="grid-item-content">Three large outdoor terraces, with NanaWalls included in base building design.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon04 />
                <div className="grid-item-content">Gas service included for tenant kitchens and heating/fire pits on terraces.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon14 />
                <div className="grid-item-content">Maximum mobile reception throughout via Distributed Antennae System (DAS) backbone.</div>
              </div>                                                                                                                            
              <div className="leasing-grid-item">
                <LeasingIcon01 />
                <div className="grid-item-content">Unobstructed views of the Bay, San Francisco and the East Bay hills.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon02 />
                <div className="grid-item-content">One of the highest volumes (15’) and tallest glass-lines (11’6”) in the Bay Area.</div>
              </div>
              <div className="leasing-grid-item">
                <LeasingIcon05 />
                <div className="grid-item-content">Pre-designed double-height spaces and/or interconnecting stairs on every floor for workplace interaction.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
