import PropTypes from "prop-types"
import React, { Component, useState } from 'react'
import Slider from "react-slick"

import Lottie from 'react-lottie'
import CloseIcon from "../../assets/close-icon.svg"
import HamburgerWhite from '../lottie/hamburger-white.json'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back } from 'gsap'
import LogoWhite from "../../assets/tt-over-white.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./gallery.scss"

import Slides from "../../data/gallery-slides.json";

import { Render01 } from '../images/render-01';
import { Render02 } from '../images/render-02';
import { Render03 } from '../images/render-03';
import { Render04 } from '../images/render-04';
import { Render05 } from '../images/render-05';
import { Render06 } from '../images/render-06';
import { Render07 } from '../images/render-07';
import { Render08 } from '../images/render-08';
import { Render09 } from '../images/render-09';
import { Render10 } from '../images/render-10';
import { Render11 } from '../images/render-11';
import { Render12 } from '../images/render-12';
import { Render13 } from '../images/render-13';
import { Render14 } from '../images/render-14';
import { Render15 } from '../images/render-15';
import { Render16 } from '../images/render-16';
import { Render17 } from '../images/render-17';
import { Render18 } from '../images/render-18';
import { Render19 } from '../images/render-19';
import { Render20 } from '../images/render-20';
import { Render21 } from '../images/render-21';
import { Render22 } from '../images/render-22';
import { Render23 } from '../images/render-23';
import { Render24 } from '../images/render-24';
import { Render25 } from '../images/render-25';



export default class Gallery extends Component {

  constructor(props) {
    super(props);

    this.state = { nav1: false, nav2: false, currentSlide_counter: 0, slides: [], currentCaption: '', currentSlider: '', galleryInitImg: null }

    this.slickViewSingle = 1;
    this.slickViewFull = 5;
    this.totalSlides = Slides.length;

    this.slider1 = React.createRef();
    this.sliderWrapper1 = React.createRef();

    this.slider2 = React.createRef();
    this.sliderWrapper2 = React.createRef();

    this.sliderModal = React.createRef();

    this.initialCaption = '';

    this.isDragging = false;
    this.isChanging = false;
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      currentSlider: 'single',
      currentSlide_counter:0,
      initialCaption: Slides[0].caption,
      currentCaption: Slides[0].caption,
      slides: Slides,
      lockScroll: false
    }); 

   TweenMax.set(this.sliderWrapper2.current, {x: '-3000px' });
  }

  componentDidUpdate(prevProps) {
    if(this.props.galleryInitImg != null && prevProps.openState == 'closed'){
      const slideIndex = Slides.map(e => e.id).indexOf(this.props.galleryInitImg);
      if(slideIndex != -1){
        this.slider1.slickGoTo(slideIndex, true);
      }
    }
  } 

  toggleStateChange = () => {
    if (this.state.currentSlider === 'full') {    
       TweenMax.set(this.sliderWrapper2.current, { x: 0 } );
       TweenMax.set(this.sliderWrapper1.current, { x: '-3000px' } );
       TweenMax.to(this.sliderWrapper2.current, 1, {opacity: 1 } );     
    } else {
       TweenMax.set(this.sliderWrapper1.current, { x: 0 } );
       TweenMax.set(this.sliderWrapper2.current, { x: '-3000px' } );
       TweenMax.to(this.sliderWrapper1.current, 1, {opacity: 1 } );         
    }
  }

  toggleSlickView = () => {
    if (this.state.currentSlider === 'full') {
      TweenMax.to(this.sliderWrapper1.current, .2, {opacity: 0, onComplete: this.toggleStateChange} );
      this.setState({currentSlider: 'single' })
    } else {
      TweenMax.to(this.sliderWrapper2.current, .2, {opacity: 0, onComplete: this.toggleStateChange} );
      this.setState({currentSlider: 'full' })
    }
  }

  slideSelected = () => {
    if(this.state.currentSlider == 'full'){    
      this.toggleSlickView();
    }
  }

  toggleGalleryInner = () => {
    this.props.toggleGallery();
    if (this.state.currentSlider === 'full') {
      this.toggleSlickView();
    }
  }

  // Mapping Imgs string name with Components 
  imageTypes = {
    'Render01': Render01,
    'Render02': Render02,
    'Render03': Render03,
    'Render04': Render04,
    'Render05': Render05,
    'Render06': Render06,
    'Render07': Render07,
    'Render08': Render08,
    'Render09': Render09,
    'Render10': Render10,
    'Render11': Render11,
    'Render12': Render12,
    'Render13': Render13,
    'Render14': Render14,
    'Render15': Render15,
    'Render16': Render16,
    'Render17': Render17,
    'Render18': Render18,
    'Render19': Render19,
    'Render20': Render20,
    'Render21': Render21,
    'Render22': Render22,
    'Render23': Render23,
    'Render24': Render24,
    'Render25': Render25
  };

  ImageSelector = (imgName, viewType, viewMode) => {
    const ImageComponent = this.imageTypes[imgName];
    if (ImageComponent){
      return <ImageComponent type={viewType} mode={viewMode} />
    }
  }

  sliderClick = () => {
    if (!this.isDragging && !this.isChanging) {
      this.slideSelected();
    }
  }

  render() {
    const hamburgerWhiteOpts = {
      loop: false,
      autoplay: false, 
      animationData: HamburgerWhite
    };

    var settingsSingle = {
      arrows: true,
      dots: false,
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      speed: 600,
      focusOnSelect: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'ease-in-out',
      className: 'gallery',
      variableWidth: false,
      onInit: (arg) => {
        this.setState({initialCaption: 'initialized'})
      },
        responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: '0px',
      }
    }
  ]

    };

    var settingsFull = {
      arrows: false,
      dots: false,
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      speed: 600,
      focusOnSelect: true,
      slidesToShow: 5,
      swipeToSlide: true,
      cssEase: 'ease-in-out',
      className: 'gallery',
      variableWidth: true,
      beforeChange: (prev) => {
        this.isChanging = true;
      },
      afterChange: (prev) => {
        if (!this.isDragging) this.slideSelected();
        this.isDragging = false;
        this.setState({currentSlide_counter: prev, currentCaption: this.state.slides[prev].caption});
        this.isChanging = false;
      },
      onSwipe: (prev, next) => {
        this.isDragging = true;
      },
      onInit: (arg) => {
        
      }
    };

    return (
      <div id="slider-modal" ref={this.sliderModal} className={'slider modal ' + this.props.openState}>

        <AniLink id="modal-logo-link" className="site-logo" fade to="/">
          <LogoWhite className="logo-white"/>
        </AniLink>
        <div className={'gallery-wrapper ' + 'view-mode-single'} ref={this.sliderWrapper1}>
          <Slider  {...settingsSingle} 
                  asNavFor={this.state.nav2}
                  ref={slider => (this.slider1 = slider)} >

            {this.state.slides.map(slide => (
              <div key={slide.id} className="slide">
                {this.ImageSelector(slide.comp,'gallery','contain')}
              </div>
            ))}
          </Slider>
        </div>


        <div onClick={this.sliderClick} className={'gallery-wrapper ' + 'view-mode-full'} ref={this.sliderWrapper2} >
          <Slider {...settingsFull}          
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)} >
                        {this.state.slides.map(slide => (
              <div key={slide.id} style={{ width: `${slide.thumbWidth}px` }} className={"slide " + (slide.sectionStart ? 'section-break-wrapper' : 'hidden')}>
                {this.ImageSelector(slide.comp,'gallery','cover')}
                {slide.sectionStart &&
                  <div className="section-break">
                    {slide.sectionStart}
                  </div>
                }
              </div>
            ))}
          </Slider>
        </div>

        <div className="hamburger-wrapper">
          <div className="hamburger-icon hamburger-white" onClick={this.toggleGalleryInner}>
            <Lottie options={hamburgerWhiteOpts} height={30} width={30} />
          </div>
        </div>

        <div className={'toggleSlickView view-mode-' + this.state.currentSlider} onClick={this.toggleSlickView}>
          <span className="show-all">+ show all</span>
          <span className="hide-all">- hide all</span>
        </div>
        <div className={'slide-caption ' + this.state.currentSlider}>{this.state.currentCaption}</div>
        <div className="slider-count">
          <span>{this.state.currentSlide_counter + 1}</span><span className="total-slides"> / {this.totalSlides}</span>
        </div>
      </div>
    );
  }
}
