import Link from "../shared/customlink.js"
//import { Link } from "gatsby"

import "./footer.scss"

import React, { Component } from 'react'

import LogoWhite from "../../assets/tt-over-white.svg";
import CushmanLogo from "../../assets/cushman-wakefield-logo-small.svg";
import JllLogo from "../../assets/jll-logo-small.svg";
import TmgLogo from "../../assets/tmg-partners-logo.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import LinkedinIcon from "../../assets/linkedin-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import TopArrow from "../../assets/up-icon-blue.svg";

var legalPDF = require.context('../../downloadable', true)

// import Typed from 'typed.js';
// import TypeHeader from "./typeheader"

export default class Footer extends Component {

  componentDidMount() {
  }

  scrollToTop = () => {
    var topPage = document.getElementById('___gatsby')
    topPage.scrollIntoView({ behavior: 'smooth' })
  }

  render() {

    return (
    <div className="footer block-container" >
      <div className="block-container-inner">
        <div className="content-row">
          <div className="content-col col-1">
            <div className="tmg-logo"><TmgLogo /></div>
            <div className="footer-content copyright"><p>©2019 TMG Partners, All Rights Reserved.</p></div>
            <div className="footer-content legal"><p><a target="_blank" href={legalPDF(`./tt_legal.pdf`)}>Terms & Conditions</a></p></div>
            <div className="partner-logos">
              <a href="https://www.us.jll.com/"><JllLogo /></a>
              <a href="http://www.cushmanwakefield.com"><CushmanLogo /></a>
            </div>
          </div>
          <div className="content-col col-2">
            <LogoWhite />
            <div className="footer-content address"><p>475 West Grand Avenue<br />Oakland, CA 94612</p></div>
            <div className="footer-social-icons">
              <div className="social-icon"><a href="https://www.instagram.com/telegraph_tower/" target="_blank"><InstagramIcon /></a></div>
              <div className="social-icon"><a href="https://www.linkedin.com/company/telegraph-tower/about/" target="_blank"><LinkedinIcon /></a></div>
              <div className="social-icon"><a href="https://www.facebook.com/Telegraph-Tower-113188146752293" target="_blank"><FacebookIcon /></a></div>
            </div>
          </div>
          <div className="content-col col-3">
            <ul className="footer-menu">
              <li><Link to="/leasing"><span className="index">01</span>leasing</Link></li>
              <li><Link to="/work-place"><span className="index">02</span>work/place experience</Link></li>
              <li><Link to="/work-life"><span className="index">03</span>work/life experience</Link></li>
              <li><Link to="/oakland-rising"><span className="index">04</span>oakland rising</Link></li>
            </ul>
          </div>
        </div>
        <div className="scrolltotop" onClick={this.scrollToTop}><div className="top-arrow"><TopArrow /></div>Top</div>
      </div>
    </div>
    )
  }
}
