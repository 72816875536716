import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import AnimHeader from "../shared/animheader"

import NewAgencyLogo from "../../images/newagency_logo.png";
import CushmanLogo from "../../assets/cushman-wakefield-logo-small.svg";
import PicturyLogo from "../../images/pictury_logo.png";
import SavageLogo from "../../assets/savage-logo.svg";

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import "./marketing-partners-block.scss"


export default class MarketingPartners extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }


  render() {

    return (
      <div>
        <AnimHeader typeText={[`marketing partners`]} titleAlign="center" />
        <div className="marketing-partners-content">
          <div className="content-row">
            <div className="content-col col-1">
              <div className="col-inner">
                <img src={NewAgencyLogo} className={`newagencyLogo`}/>
                <div className="partner-name">Strategy & Positioning<br />Marianna Leuschel</div>
              </div>
            </div>
            <div className="content-col col-2">
              <div className="col-inner">
                <CushmanLogo />
                <div className="partner-name">Brand & Creative Vision<br/>Ville Kansanen </div>
              </div>
            </div>
            <div className="content-col col-3">
              <div className="col-inner">
                <img src={PicturyLogo} className={`picturyLogo`}/>
                <div className="partner-name">Visualization</div>
              </div>
            </div>
            <div className="content-col col-4">
              <div className="col-inner">
                <SavageLogo />
                <div className="partner-name">Website Design & Development</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
