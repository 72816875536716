import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import Link from "../shared/customlink.js"

import "./flyout-menu.scss"


export default class Flyoutmenu extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    menuState: PropTypes.string,
  }

  static defaultProps = {
    menuState: `closed`
  }

  openGallery = () => {
    this.props.menuUpdate();
    this.props.galleryHandler(); 
  }

  openVideo = () => {
    this.props.menuUpdate();
    this.props.videoHandler(); 
  }

  toggleMenu = () => {
    this.props.menuUpdate()
  }

  componentDidMount() {
    // this.toggleGallery = this.layout.current.toggleGallery;
  }

  render() {
    return (

      <div id="flyout-menu" className={this.props.menuState}>
        <div className="flyout-menu-inner">
          <div className="menu-items-row">
            <div className="menu-item-col menu-item-1">
              <div className="index heading">01</div>
              <Link to="/leasing" clickCallback={this.toggleMenu}>
                <div className="heading">leasing</div>
                <div className="divider"></div>
                <div className="menu-item-descr">Building facts, figures, numbers, maps, plans — and the team behind them.</div>
              </Link>
              <div className="mobile-divider"></div>
            </div>
            <div className="menu-item-col menu-item-2">
              <div className="index heading">02</div>
              <Link to="/work-place" clickCallback={this.toggleMenu}>
                <div className="heading">work/place <br/ >experience</div>
                <div className="divider"></div>
                <div className="menu-item-descr">Connect to next-gen features and exciting design.</div>
              </Link>
              <div className="mobile-divider"></div>
            </div>
            <div className="menu-item-col menu-item-3">
              <div className="index heading">03</div>
              <Link to="/work-life" clickCallback={this.toggleMenu}>
                <div className="heading">work/life <br />experience</div>
                <div className="divider"></div>
                <div className="menu-item-descr">Connect to art, culture, cuisine, coffee, music; and the Bay’s best commute.</div>
              </Link>
              <div className="mobile-divider"></div>
            </div>
            <div className="menu-item-col menu-item-4">
              <div className="index heading">04</div>
              <Link to="/oakland-rising" clickCallback={this.toggleMenu}>
                <div className="heading">oakland <br />rising</div>
                <div className="divider"></div>
                <div className="menu-item-descr">Connect to the dynamic future of Oakland and the East Bay.<br/>&nbsp;</div>
              </Link>
              <div className="mobile-divider"></div>
            </div>
          </div>
          <div className="flyout-menu-footer">
            <div className="flyout-menu-footer-inner">
              <div className="flyout-menu-footer-item flyout-menu-connect">
                <div className="flyout-footer-header heading">Connect</div>
                <div className="flyout-footer-content">hello@telegraphtower.town</div>
              </div>
              <div className="flyout-menu-footer-item flyout-menu-quicklinks">
                <div className="flyout-footer-header heading">Quick Links</div>
                <div className="flyout-footer-content">
                  <span className="flyout-footer-content-link" onClick={this.openVideo} >watch the teaser</span> / 
                  <span className="flyout-footer-content-link" onClick={this.openGallery} >gallery</span>/
                  <Link className="flyout-footer-content-link" to="/leasing#leasing-section-4" clickCallback={this.toggleMenu}>highlights</Link> / 
                  <Link className="flyout-footer-content-link" to="/leasing#leasing-section-map" clickCallback={this.toggleMenu}>maps</Link>/ 
                  <Link className="flyout-footer-content-link" to="/leasing#leasing-section-specs" clickCallback={this.toggleMenu}>specs</Link>/ 
                  <Link className="flyout-footer-content-link" to="/leasing#fact-sheets" clickCallback={this.toggleMenu}>fact sheets</Link>/ 
                  <Link className="flyout-footer-content-link" to="/leasing#the_team" clickCallback={this.toggleMenu}>team</Link>
                </div>
              </div>
              <div className="flyout-menu-footer-item flyout-menu-follow">
                <div className="flyout-footer-header heading">Follow</div>
                <div className="flyout-footer-content">IG / TW / IN</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

