import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import AnimHeader from "../shared/animheader"

import CushmanLogo from "../../assets/cushman-wakefield-logo-small.svg";
import JllLogo from "../../assets/jll-logo-small.svg";

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import "./leasing-block.scss"


export default class LeasingBlock extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }


  render() {

    return (
      <div>
        <AnimHeader typeText={[`leasing`]} titleAlign="center" />
        <div className="leasing-block-content">
          <div className="content-row">
            <div className="content-col col-1">
              <div className="col-inner">
                <JllLogo />
                <div className="content-text"><span>JLL buys, builds, occupies and invests in a variety of assets including industrial, commercial, retail, residential and hotel real estate. From tech startups to global firms, their clients span industries including banking, energy, healthcare, law, life sciences, manufacturing and technology.</span></div>
              </div>
            </div>
            <div className="content-col col-2">
              <div className="col-inner">
                <CushmanLogo />
                <div className="content-text"><span>Cushman & Wakefield is a force for action. Fueled by ideas, expertise and dedication across borders and beyond service lines, they create real estate solutions to prepare their clients for what’s next.</span></div>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
  }

}
