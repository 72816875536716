import React, { Component, useState } from 'react'
import Layout from "../components/layout/layout"
import SectionContainer from "../components/layout/sectioncontainer"

import ConnectBlock from "../components/sections/connectblock"
import MarketingPartners from "../components/sections/marketing-partners-block"
import LeasingBlock from "../components/sections/leasing-block"
import ContentBlock2Col from "../components/sections/content-block-2col"
import LeasingGrid from "../components/sections/leasing-grid"

import Hero from "../components/shared/hero"

import { Leasing02 } from "../components/images/leasing-02"

import SpecsGrid from "../components/grid/specs_grid"

import AnimHeader from "../components/shared/animheader"
import SEO from "../components/seo"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import LocationMap from "../components/shared/locationmap.js"
var specPDFs = require.context('../images/specs_pdfs', true)

export default class LeasingPage extends Component {
  
  constructor({props, transitionStatus, entry, exit, location}) {
    super(props);

    this.signalAnimComplete = false;
    this.locationState = location.state;
    this.layout = React.createRef();

    this.state = {
      signalAnimComplete: false,
      defaultFactSheetTabIndex: 0
    }
  }

  componentDidMount() {

    if (this.locationState) {
      let tabState = this.locationState.locationState;

      if (tabState == 'safety-security-tab') {
        this.setState( { defaultFactSheetTabIndex: 2 } ); 
      }
    }
    
    this.toggleGallery = this.layout.current.toggleGallery;
    this.navSwitchCallback = this.layout.current.navSwitchCallback;
    this.progressCheckCallback = this.layout.current.progressCheckCallback;
    this.layout.current.runSignalAnim(() => { this.setState({'signalAnimComplete': true }) })


  }

  render() {

    let floorplan_all_pdf = 'TelegraphTower-Plans';
    return (
      <Layout ref={this.layout}>
        <SEO title="Leasing" description="Discover all the relevant facts, figures, numbers, maps, plans — and the team behind them." />

          <Hero pageClass="leasing" headerText={[`leasing`]}
            descText="Discover all the relevant facts, figures, numbers, maps, plans — and the team behind&nbsp;them." 
            locationState={this.locationState}
            navSwitchCallback={this.navSwitchCallback}
            progressCheckCallback={this.progressCheckCallback} />
        
          {/* SECTION 2 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="20" id="leasing-section-2" inverted={false}>
            <ContentBlock2Col 
              imgSrc='Place02' 
              imgPos="right" 
              animTitle={[`project overview`]} 
              titleAlign="right"
              brochureLink={true}
              animText={
                [`<strong>Telegraph Tower</strong> — an 875,000 square foot next-generation office building<br/><br/><strong>Location</strong> — In the heart of Uptown Oakland, the East Bay’s center for diverse culture and world-class restaurants, arts, and entertainment scene<br/><br/><strong>Building Features</strong> — 30,000 sq. ft. + floorplates, 15 foot tall floors with 11’6” glass line, outdoor space on every floor<br/><br/><strong>Unobstructed Views</strong> — 420’ tall building with views of San Francisco, the Bay, and the East Bay Hills<br/><br/><strong>Premier Transit & Housing Accessibility</strong> — Located adjacent to the 19th Street BART Station, more than 9,000 new residential units within a 10 minute walk or bike ride`]
              }
            />
        </SectionContainer>

        {/* SECTION 3 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="28" id="leasing-section-specs" inverted={true}>
            <ContentBlock2Col 
              imgSrc='' 
              imgPos="left" 
              animTitle={[`project specs`]} 
              descList={
                [
                  [
                    `28 Floors`,
                    [`- 420' tall`,`- 875,000 RSF`,`- Retail, amenity, and office space`]
                  ],
                  [
                    `Large Floorplates`,
                    [`- 37K RSF (5th-6th Floors)`,`- 34K RSF (7th-18th Floors)`,`- 31k RSF (19th-28th Floors)`]
                  ],
                  [
                    `Virtually Column Free`,
                    [`- 4 interior columns (6th – 17th Floors)`,`- 3 interior columns (18th – 27th Floors)`,`- 30'-40' typical E-W spans`,`- 42'-49' typical N-S spans`]
                  ],
                  [ 
                    `Floor Height and Glass Line`,
                    [`- 15' deck-to-deck on typical floors`,`- 11'6'' vision glass`,`- Approximately 30' deck-to-deck in the lobby`]
                  ],
                  [
                    `Interconnected Workspaces`,
                    [`- Opportunity for double-height spaces and/or interconnecting stairs on every floor`]
                  ],
                  [
                    `Private Terraces and Balconies`,
                    [`- 33 balconies, 3 terraces`,`- +/-30K RSF of total outdoor space`,`- Outdoor space on every floor`]
                  ],
                  [
                    `Site Address:`,
                    [`- 475 West Grand Avenue, Oakland, CA 94612`]
                  ],
                  [
                    `Architect`,
                    [`- Solomon Cordwell Buenz`]
                  ]
                ]
              }
            />
        </SectionContainer>

        {/* SECTION 4 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="36" id="leasing-section-4" inverted={false}>
          <AnimHeader typeText={[`we’ve thought`,`of everything`]} />
          <LeasingGrid />
        </SectionContainer>


        {/* SECTION MAP */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="42" id="leasing-section-map" inverted={false}>
          <LocationMap />
        </SectionContainer>


        {/* SECTION 5 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} id="fact-sheets" dashIncrement="48" inverted={false}>
          <AnimHeader typeText={['project details']} titleAlign="center" />
          <div className="block-container tabs-container fact-sheets" >
            <div className="block-container-inner">
              <Tabs selectedIndex={this.state.defaultFactSheetTabIndex} onSelect={defaultFactSheetTabIndex => this.setState({ defaultFactSheetTabIndex })}>
                <TabList>
                  <Tab>transportation</Tab>
                  <Tab>wellness</Tab>
                  <Tab>safety & security</Tab>
                </TabList>

                <TabPanel>
                  <div className="block-container">
                    <div className="block-container-inner">
                      <Leasing02 />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <ContentBlock2Col 
                    imgSrc='Leasing03' 
                    imgPos="left"
                    rightWidth={66}
                    leftWidth={33} 
                    contentPadding={false}
                    animTitle={[`wellness`]}
                    titleSize="small" 
                    descList={
                      [
                        [
                          `Dramatic high ceiling space 15’ deck-to-deck with 11’6” vision glass allows for maximum daylight/sunlight`
                        ],
                        [
                          `10,000 SF fitness center with showers and lockers on 2nd floor`
                        ],
                        [
                          `LEED Gold sustainable workplace`
                        ],
                        [
                          `3 outdoor terraces and 33 balconies. 30,000 SF usable outdoor area. Terraces connect seamlessly to interiors with operable glass NanaWalls.`
                        ],
                        [
                          `100% outside air`
                        ],
                        [
                          `Tenants can control own temperature`
                        ],
                        [
                          `120 bike parking stalls`
                        ],
                        [
                          `Dramatic art installations in lobby and café areas inspire creativity`
                        ],
                        [
                          `Pet friendly`
                        ],
                        [
                          `96 Walk Score`
                        ],
                        [
                          `260 days of sunshine`
                        ] 
                      ]
                    }
                    />
                </TabPanel>
                <TabPanel>
                  <ContentBlock2Col 
                    imgSrc='Leasing05' 
                    imgPos="left"
                    rightWidth={66}
                    leftWidth={33} 
                    contentPadding={false}
                    animTitle={[`safety & security`]} 
                    titleSize="small"
                    descList={
                      [
                        [
                          `Seismic performance in excess of building code for office buildings; collapse prevention standards equal to hospitals, public transit stations, and emergency operations centers`
                        ],

                        [
                          `Exiting stairs pre-wired for tenant security systems to allow for secure inter floor traffic`
                        ],

                        [
                          `24/7 on-site lobby security`
                        ],

                        [
                          `Perimeter bollards, 2-pass point security with video surveillance`
                        ],

                        [
                          `Biometrics in lobby`
                        ],

                        [
                          `Emergency generator`
                        ]
                      ]
                    }
                    />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </SectionContainer>

        {/* SECTION 4 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="50" inverted={false}>
          <AnimHeader typeText={['plans']} titleAlign="center" />
          <div className="block-container tabs-container specs" >
            <div className="block-container-inner">
              <Tabs>
                <TabList>
                  <Tab>Floor Plans</Tab>
                  <Tab>Test Fits</Tab>
                  <Tab>Building</Tab>
                </TabList>

                <TabPanel>
                  <SpecsGrid specsType="floorplans"/>
                </TabPanel>
                <TabPanel>
                  <SpecsGrid specsType="testfits"/>
                </TabPanel>
               <TabPanel>
                  <SpecsGrid specsType="building"/>
                </TabPanel>
              </Tabs>
            </div>
            <a href={specPDFs(`./${floorplan_all_pdf}.pdf`)}  rel="noopener noreferrer" target="_blank" className="btn">{`download all plans (pdf)`}</a>
          </div>
        </SectionContainer>

        {/* SECTION 6 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} id="the_team" dashIncrement="60" inverted={false}>
          <AnimHeader typeText={['the team']} titleAlign="center" />
          <div className="block-container tabs-container the-team" >
            <div className="block-container-inner">
              <ContentBlock2Col 
                imgSrc='TmgPartners' 
                imgPos="left"
                rightWidth={66}
                leftWidth={33} 
                contentPadding={false}
                animTitle={[`ownership`]} 
                titleSize="small"
                animText={`TMG Partners is a privately-held full service development company headquartered in San Francisco focusing on urban infill projects in the San Francisco Bay Area. Our exclusive focus in the Bay Area helps us understand the nuances of market trends and timing`}
                />

              <ContentBlock2Col 
                imgSrc='ScbArchitects' 
                imgPos="right"
                rightWidth={45}
                leftWidth={55} 
                contentPadding={false}
                animTitle={[`architects`]} 
                titleSize="small"
                animText={`SCB is an architecture, interior design, and planning firm that practices nationally from offices in Chicago and San Francisco. Since 1931, SCB has made a lasting impact on the nation’s skyline, campuses, and neighborhoods, helping our clients across the country achieve their goals, serve their constituencies, and make their mark.`}
                />
            </div>
          </div>
        </SectionContainer>

        {/* SECTION 7 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} id="leasing-block" dashIncrement="70" inverted={false}>
          <div className="block-container tabs-container leasing-block" >
            <div className="block-container-inner">
              <LeasingBlock />
            </div>
          </div>
        </SectionContainer>

        {/* SECTION 8 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} id="marketing-partners" dashIncrement="80" inverted={false}>
          <div className="block-container tabs-container marketing-partners" >
            <div className="block-container-inner">
              <MarketingPartners />
            </div>
          </div>

        </SectionContainer>

        {/* SECTION 1 */}
        <SectionContainer dashDetect={this.state.signalAnimComplete} navSwitchCallback={this.navSwitchCallback} dashIncrement="90" id="first-section" inverted={false}>
          <ConnectBlock />
        </SectionContainer>

      </Layout>
    )
  }
}
