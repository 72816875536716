/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import PropTypes from "prop-types"
import React, { Component, useState } from 'react'
import { StaticQuery, graphql } from "gatsby"

import ReactGA from 'react-ga'

import Header from "./header"
import { Helmet } from "react-helmet"

import Gallery from "../shared/gallery"
import Video from "../shared/video"
import Footer from "./footer"

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';

import { ParallaxProvider } from 'react-scroll-parallax';

import LogoWhite from "../../assets/tt-over-white.svg";

import Lottie from 'react-lottie'
import Preloader from '../lottie/preloader.json'

import "./layout-gb.css"
import "./layout.scss"

export default class Layout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      galleryState : 'closed',
      galleryInitImg: '',
      videoState : 'closed',
      navState: `white`,
      prevNavState: `white`,
      dashComplete: false,
      isPaused: false,
      preloaderState: 'open',
      menuState: '',
      preventNavStateChange: false,
      hideVidBtn: false
    }

    this.signalCollection = [];
    this.mainContainer = React.createRef();
    this.header = React.createRef();
  }


  lockBodyScrolling = () => {
    // Get scroll position to be able to restore it in onCloseModal
    this.bodyScrollPos = document.body.scrollTop
      || document.documentElement.scrollTop || 0;
    this.appWrapper.style.position = 'fixed';
    this.appWrapper.style.top = `-${this.bodyScrollPos}px`;
  }

  restoreBodyScrolling = () => {
    this.appWrapper.style.removeProperty('position');
    this.appWrapper.style.removeProperty('top');

    window.addEventListener('scroll', (this.handleScroll), { passive: true })
    document.documentElement.scrollTop = document.body.scrollTop = this.bodyScrollPos;
  }

  componentDidMount = () => {
    this.appWrapper = document.getElementById('___gatsby');
    // setTimeout(
    //   function() {
    //     this.setState({preloaderState: 'closed'});
    //   }
    //   .bind(this),
    //   3000
    // );
    // setTimeout(
    //   function() {
    //     this.setState({preloaderState: 'closed hidden'});
    //   }
    //   .bind(this),
    //   3500
    // );
    this.setState({menuState:this.header.current.state.menuCurrentState});

  }

  handleScroll = (event) => {
     if (window.scrollY != this.bodyScrollPos) {
      this.setState({preventNavStateChange: false});
      window.removeEventListener('scroll', this.handleScroll)
     }
  }

  runSignalAnim = (callback) => {
    let signalItems = this.mainContainer.current.querySelectorAll('.signal-item');

    TweenMax.set(signalItems, {opacity: 0, y: -10 });

    let tl = new TimelineMax();
    tl.eventCallback("onComplete", callback);
    tl.staggerTo(signalItems, .1, {opacity: 1, y: 0 }, .05);
    tl.play();
  }

  toggleGallery = (imgId) => {
    this.setState({galleryInitImg: imgId});
    if (this.state.galleryState === 'closed') {
      this.setState({prevNavState: this.state.navState});
      this.setState({galleryState: 'open'});
      this.lockBodyScrolling();

      // track
      this.logGalleryOpen();
    } else {
      this.setState({navState: this.state.prevNavState, preventNavStateChange: true});
      this.setState({galleryState: 'closed'});
      this.restoreBodyScrolling();
    }
  }

  toggleVideo = () => {
    if (this.state.videoState === 'closed') {
      this.setState({prevNavState: this.state.navState});
      this.setState({videoState: 'open'});
      this.lockBodyScrolling();

      // track
      this.logVideoOpen();
    } else {
      this.setState({navState: this.state.prevNavState, preventNavStateChange: true});
      this.setState({videoState: 'closed'});
      this.restoreBodyScrolling();
    }
  }

  logVideoOpen = () => {
    ReactGA.event({
      category: 'Video View',
      action: 'User watched the teaser video'
    })    
  }

  logGalleryOpen = () => {
    ReactGA.event({
      category: 'Gallery View',
      action: 'User viewed the gallery'
    })    
  }

  navSwitchCallback = (inverted) => {

    if (!this.state.preventNavStateChange) {
      if (inverted) {
        this.setState({navState: 'white'});
      } else {
        this.setState({navState: 'black'});
      }
    }
  }

  progressCheckCallback = (progress) => {

    let opacityOffset = +Number(progress).toFixed(2);
    let opacityValue = Number(1 - opacityOffset);

    if (opacityValue < .9 && this.state.hideVidBtn == false) {
      this.setState({hideVidBtn: true});
    } else if (opacityValue > .9 && this.state.hideVidBtn == true) {
      this.setState({hideVidBtn: false});
    }
    //this.setState({videoBtnOpacity: opacityValue })
    //if (opacityValue )
    //this.setState({heroProgress: progress});
  }

  preloaderAnim = () => {
    this.setState({isPaused: false})
  }

  hidePreloader = () => {
    this.setState({preloaderState: 'closed'});
    setTimeout(
      function() {
        this.setState({preloaderState: 'closed hidden'});
      }
      .bind(this),
      500
    );
  }

  render() {

    let footerRender = <Footer />;
    if (this.props.nullFooter) {
      footerRender = null;
    }

    const preloaderOpts = {
      loop: true,
      autoplay: false, 
      animationData: Preloader,
      rendererSettings: {
        //preserveAspectRatio: 'xMidYMid slice'
      }
    }  

    return (
    <ParallaxProvider>
      
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/vmr3njl.css" />
        <link href="https://fonts.googleapis.com/css?family=Fira+Mono|Montserrat:400,700&display=swap" rel="stylesheet" />
      </Helmet>

      <StaticQuery
          query={graphql`
            query HeadingQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <Header siteTitle={data.site.siteMetadata.title} hideVidBtn={this.state.hideVidBtn} scrollLock={this.lockBodyScrolling} scrollRestore={this.restoreBodyScrolling} toggleGallery={this.toggleGallery} toggleVideo={this.toggleVideo} navState={this.state.navState} ref={this.header} currentPageName={this.props.children[0].props.title} />
          )}
        />


      {this.props.children[0].props.title == 'Home' &&
        <div className={`preloader-wrapper ` + this.state.preloaderState}>
          <Lottie options={preloaderOpts} width={`100wv`} height={`100vh`} isPaused={this.state.isPaused} />
        </div>
      }

      <div className="site-main-wrapper" ref={this.mainContainer}>

        <main id="site-main">{this.props.children}</main>

        <Gallery openState={this.state.galleryState} toggleGallery={this.toggleGallery} galleryInitImg={this.state.galleryInitImg} />

        <Video openState={this.state.videoState} toggleVideo={this.toggleVideo} />

      </div>
      {footerRender}
    </ParallaxProvider>
    )
  }
}
