import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import Lottie from 'react-lottie'
import HamburgerWhite from '../lottie/hamburger-white.json'

import LogoWhite from "../../assets/tt-over-white.svg"

import "./video.scss"
import ReactPlayer from 'react-player'

export default class Video extends Component {

  constructor(props) {
    super(props);
    // set the initial state to non-init
  }

  componentDidMount() {
    this.videoModal = React.createRef();
    this.player = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openState == 'closed' && this.props.openState == "open") {
      this.player.current.player.player.play();
    }
  }

  toggleVideoInner = () => {
    this.player.current.player.player.pause();
    this.props.toggleVideo();
  }


  render() {
    const hamburgerWhiteOpts = {
      loop: false,
      autoplay: false, 
      animationData: HamburgerWhite
    };

    return (
      <div id="video-modal" ref={this.videoModal} className={'video modal ' + this.props.openState}>

        <div className="video-modal-wrapper">
          <ReactPlayer ref={this.player} config={{
              vimeo: {
                playerOptions: { controls: true },
                preload: true
              }
            }}
            onEnded={this.props.toggleVideo}
            url='https://vimeo.com/362142986' width="100%" height="100%" />
        </div>

        <div className="hamburger-wrapper">
          <div className="hamburger-icon hamburger-white" onClick={this.toggleVideoInner}>
            <Lottie options={hamburgerWhiteOpts} height={30} width={30} />
          </div>
        </div>

      </div>
    );
  }
}
