
import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import ReactMapboxGl, { Layer, Feature, Marker, ZoomControl, GeoJSONLayer } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import TTMapMarker from "../../images/maps/tt-map-marker-v2.png";
import BusMapMarker from "../../images/maps/bus-map-marker.png";
import TrainMapMarker from "../../images/maps/bart-map-marker-v2.png";
import FerryMapMarker from "../../images/maps/ferry-map-marker-v2.png";
import FreewayMapMarker from "../../images/maps/freeway-map-marker-v2.png";
import TransitMapMarker from "../../images/maps/transit-map-marker-v2.png";
import CarMapMarker from "../../images/maps/car-map-marker.png";

// category markers
import CultureMapMarker from "../../images/maps/culture-map-marker-v2.png";
import DiningMapMarker from "../../images/maps/dining-map-marker-v2.png";
import CafeMapMarker from "../../images/maps/cafe-map-marker-v2.png";
import BarMapMarker from "../../images/maps/bars-map-marker-v2.png";

import "./locationmap.scss"

import Locations from "../../data/locations.json";

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiY3NjaGFlZGVsIiwiYSI6ImNqM2cyMmM5cDAxd28ycXQ4MTl2cml0ajcifQ.By81C7yn_tT7y3Vcd877KQ'
});

const geojsonFerry = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.279530,
            37.795670
          ],
          [
            -122.279260,
            37.796230
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.279260,
            37.796230
          ],
          [
            -122.276755,
            37.79529
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.276755,
            37.79529
          ],
          [
            -122.270578,
            37.805336
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.270578,
            37.805336
          ],
          [
            -122.269056,
            37.811582
          ]
        ]
      }
    }
  ]
};

const geojsonFreeway = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.269056,
            37.811582
          ],
          [
            -122.267896,
            37.816241
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.267896,
            37.816241
          ],
          [
            -122.269775,
            37.816658
          ]
        ]
      }
    }
  ]
};

//Latitude: 37.79529, Longitude: -122.276755
const geojsonTransit = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [ 
            -122.276276,
            37.796034
          ],
          [
            -122.273927,
            37.795114
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [ 
            -122.273927,
            37.795114
          ],
          [
            -122.274431,
            37.794288
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [ 
            -122.274431,
            37.794288
          ],
          [
            -122.276755,
            37.79529
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.276755,
            37.79529
          ],
          [
            -122.266811,
            37.811391
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.266811,
            37.811391
          ],
          [
            -122.265382,
            37.811432
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.265382,
            37.811432
          ],
          [
            -122.266057,
            37.808881
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.266057,
            37.808881
          ],
          [
            -122.266053,
            37.808444
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.266053,
            37.808444
          ],
          [
            -122.266991,
            37.808589
          ]
        ]
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -122.266991,
            37.808589
          ],
          [
            -122.268208,
            37.809021
          ]
        ]
      }
    }
  ]
};

const lineLayout = {
  'line-join': 'round',
  'line-cap': 'round'
};

const linePaint = {
  'line-dasharray': [0,2],
  'line-color': '#0e77b6',
  'line-width': 3.5
};

export default class LocationMap extends Component {

  static propTypes = {
    statement: PropTypes.string,
    footnote: PropTypes.string
  }

  static defaultProps = {
    statement: 'statement',
    footnote: 'footnote'
  }

  constructor(props) {
    super(props);

    this.cursorEle = React.createRef();

    this.defaultMapCenter = [`-122.268680`,`37.811520`];
    this.defaultMapZoom = [14.4];
    this.defaultMapPitch = [0];
    this.defaultMapBearing = [297];

    this.state = {
      mapCenter: this.defaultMapCenter,
      mapZoom: this.defaultMapZoom,
      mapBearing: [297],
      mapPitch: this.defaultMapPitch,
      selectedMapIndex: 0,
      fitBounds: null,
      zoomRef: [14.4],
      markerFocus: '',
      showLabels: true,
      focusItem: ''
    }

    this.restaurantFileData = Locations.filter((x) => { return x.type == 'restaurants'; });
    this.cultureFileData = Locations.filter((x) => { return x.type == 'culture'; });
    this.barFileData = Locations.filter((x) => { return x.type == 'bars'; });
    this.coffeeFileData = Locations.filter((x) => { return x.type == 'coffee'; });
    
    this.transitMarkerData = {
      'train': [`-122.268593`,`37.808289`],
      'train_2': [`-122.272065`,`37.802942`], 
      'transit': [`-122.274473`,`37.799139`],
      'transit_2': [`-122.266053`,`37.808444`],
      'ferry': [`-122.279208`,`37.795046`],
      'car': [`-122.269370`,`37.816523`],
      'freeway': [`-122.269775`, `37.816658`]
    }
  }

  componentDidMount = () => {
    let _self = this;
    TweenMax.to(_self.cursorEle.current, .5, { opacity: 1 });
  }

  centerMap = () => {
    console.log('centering map')
    console.log(this.state.mapCenter + '--' + this.state.mapZoom);
    let thisMapZoom = (this.state.selectedMapIndex != 0) ? [15] : [this.defaultMapZoom];

    this.setState({mapCenter: this.defaultMapCenter, mapZoom: thisMapZoom,showLabels: false, mapPitch: this.defaultMapPitch, mapBearing: this.defaultMapBearing});
  }

  flyToLocation = (markerType) => {
    let thisBearing = (markerType == 'ferry') ? [320] : this.defaultMapBearing;
    this.setState({mapCenter: this.transitMarkerData[markerType], mapZoom: [this.defaultMapZoom], markerFocus: markerType, mapPitch: [45], mapBearing: thisBearing})
    //this.setState({mapCenter: this.transitMarkerData[markerType]})
  }

  flyToLocationCoordinates = (locationCoordinates) => {
    this.setState({mapCenter: locationCoordinates, mapZoom: [16.5], markerFocus: '', mapPitch: this.defaultMapPitch, mapBearing: this.defaultMapBearing})
  }

  render() {

    let labelVisClass = (this.state.showLabels && this.state.zoomRef > 16) ? 'showLabels' : 'hideLabels';
    return (
        <div className="location-map-wrapper">
          <div className={'location-map-container'}>

          <div className="location-map-nav">
                  <h2 className={`typeHeader`}>
                      location
                      <span className="header-cursor" ref={this.cursorEle}></span>
                  </h2>
              <div className="tabs-container" >
                <div className="block-container-inner">
                  
                  <Tabs selectedIndex={this.state.selectedMapIndex} onSelect={selectedMapIndex => this.setState({ showLabels: false, mapPitch: this.defaultMapPitch, mapBearing: this.defaultMapBearing, selectedMapIndex, markerFocus: '', mapZoom: ((selectedMapIndex == 0) ? this.defaultMapZoom : [15]), mapCenter: [`-122.268680`,`37.811520`] })}>
                    <TabList>
                      <Tab><span>Transit</span></Tab>
                      <Tab><span>Restaurants</span></Tab>
                      <Tab><span>Bars &amp; Breweries</span></Tab>
                      <Tab><span>Coffee Shops &amp; Cafes</span></Tab>
                      <Tab><span>Entertainment &amp; Culture</span></Tab>
                    </TabList>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>

            <div className="location-map">

                <Map
                  style="mapbox://styles/cschaedel/ck12lm4vr03z91cmws66qxdpk/draft"
                  containerStyle={{
                    height: '100%',
                    width: '100%'
                  }}
                  scrollWheelZoom="false"
                  scrollZoom="false"
                  bearing={this.state.mapBearing}
                  center={this.state.mapCenter}
                  zoom={this.state.mapZoom}
                  pitch={this.state.mapPitch}
                  flyToOptions={{duration: 1000}}
                  onStyleLoad={ (map) => { map.scrollZoom.disable(); /*map.scrollWheelZoom.disable();*/ } } 
                  fitBounds={this.state.fitBounds}
                  onZoomStart={(map, event) => {
                    //this.setState({showLabels: false});
                  }}
                  onZoomEnd={(map, event) => {
                    if (event.fitboundUpdate) {
                      console.log('Map bounds have been programmatically changed')
                      this.setState({zoomRef: map.getZoom()})
                      console.log(map.getZoom());
                    } else {
                      console.log('Map bounds have been changed by user interaction')
                      this.setState({zoomRef: map.getZoom()})
                      console.log(map.getZoom());
                    }
                    this.setState({showLabels: true});
                  }}
                  onMoveEnd={(map, event) => {
                    let mapLng = map.getCenter().lng;//.toFixed(4);
                    let mapLat = map.getCenter().lat;//.toFixed(4);

                    let mapCenterState = [mapLng,mapLat]

                    if (this.state.haltFocusClear) {
                      this.setState({haltFocusClear: false});
                    } else {
                      this.setState({focusItem: ''});
                    }
                    this.setState({mapCenter: mapCenterState});

                    //console.log('moved lat/long' + mapLng + '---' + mapLat);
                  }}
                >


                <a class="btn solid-bckg custom-link map-control-btn" onClick={this.centerMap}>CENTER MAP</a>

                <ZoomControl/>

                <Marker
                  coordinates={[-122.268680,37.811520]}
                  anchor="center"
                  className="primaryMarker">
                  <img src={TTMapMarker} className={`markerLg`} alt="marker"/>
                </Marker>


                {this.state.selectedMapIndex == 0 && 
                <div>
                <Marker
                  coordinates={this.transitMarkerData['train']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('train')}>
                  <img src={TrainMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>19th St Bart Station</span>
                  </div>
                </Marker>

                <Marker
                  coordinates={this.transitMarkerData['train_2']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('train')}>
                  <img src={TrainMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>12th St Bart Station</span>
                  </div>
                </Marker>

                <Marker
                  coordinates={this.transitMarkerData['ferry']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('ferry')}>
                  <img src={FerryMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>SF Bay Ferry Access</span>
                  </div>
                </Marker>
  
                <Marker
                  coordinates={this.transitMarkerData['freeway']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('freeway')}>
                  <img src={FreewayMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>580 / 880 Freeway Access</span>
                  </div>
                </Marker>

                <Marker
                  coordinates={this.transitMarkerData['transit']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('transit')}>
                  <img src={TransitMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>AC Transit</span>
                  </div>
                </Marker>

                <Marker
                  coordinates={this.transitMarkerData['transit_2']}
                  anchor="center">
                  <div className={`markerWrapper`} onClick={() => this.flyToLocation('transit')}>
                  <img src={TransitMapMarker} className={`markerMedLg`} alt="marker"/>
                  <span className={`markerTitle`}>AC Transit</span>
                  </div>
                </Marker>
                </div>}

                {this.state.selectedMapIndex == 0 && this.state.markerFocus == 'ferry' &&
                <GeoJSONLayer
                  data={geojsonFerry}
                  linePaint={linePaint}
                  lineLayout={lineLayout}
                />
                }

                {this.state.selectedMapIndex == 0 && this.state.markerFocus == 'freeway' &&
                <GeoJSONLayer
                  data={geojsonFreeway}
                  linePaint={linePaint}
                  lineLayout={lineLayout}
                />
                }

               {this.state.selectedMapIndex == 0 && this.state.markerFocus == 'transit' &&
                <GeoJSONLayer
                  data={geojsonTransit}
                  linePaint={linePaint}
                  lineLayout={lineLayout}
                />
                }

                {/* restaurant markers */}
                {this.state.selectedMapIndex == 1 && this.restaurantFileData.map(marker => (
                  <Marker
                    coordinates={[marker.position.lng,marker.position.lat]}
                    anchor="center">
                    <div className={`markerWrapper`} onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}>
                    <img src={DiningMapMarker} className={`markerMed`} alt="marker"/> 
                    
                    <span className={[`markerTitle`, `${marker.offset}`, `${labelVisClass}`].join(' ')}>{marker.name}</span>
                    
                    </div>
                  </Marker>
                ))}

                {/* bars markers */}
                {this.state.selectedMapIndex == 2 && this.barFileData.map(marker => (
                  <Marker
                    coordinates={[marker.position.lng,marker.position.lat]}
                    anchor="center">
                    <div className={`markerWrapper`} onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}>
                    <img src={BarMapMarker} className={`markerMed`} alt="marker"/>
                    
                    <span className={[`markerTitle`, `${marker.offset}`, `${labelVisClass}`].join(' ')}>{marker.name}</span>
                    
                    </div>
                  </Marker>
                ))}

                {/* coffee markers */}
                {this.state.selectedMapIndex == 3 && this.coffeeFileData.map(marker => (
                  <Marker
                    coordinates={[marker.position.lng,marker.position.lat]}
                    anchor="center">
                    <div className={`markerWrapper`} onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}>
                    <img src={CafeMapMarker} className={`markerMed`} alt="marker"/>
                    
                    <span className={[`markerTitle`, `${marker.offset}`, `${labelVisClass}`].join(' ')}>{marker.name}</span>
                    
                    </div>
                  </Marker>
                ))}

                {/* culture markers */}
                {this.state.selectedMapIndex == 4 && this.cultureFileData.map(marker => (
                  <Marker
                    coordinates={[marker.position.lng,marker.position.lat]}
                    anchor="center">
                    <div className={`markerWrapper`} onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}>
                    <img src={CultureMapMarker} className={`markerMed`} alt="marker"/>
                    
                    <span className={[`markerTitle`, `${marker.offset}`, `${labelVisClass}`].join(' ')}>{marker.name}</span>
                    
                    </div>
                  </Marker>
                ))}

              </Map>
            </div>

            <div className="location-map-nav location-map-list-nav">
              <div className="content-container" >
                <div className="block-container-inner">
                  <div className={`map-poi-help`}>
                    Select a point of interest below to move to that location:
                  </div>
                   
                  {this.state.selectedMapIndex == 0 &&
                  <div className={`map-icon-transit`}>
                    <div className={[`map-icon-link`,((this.state.markerFocus == 'ferry') ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => this.flyToLocation('ferry')}><img src={FerryMapMarker} className={`markerMed`} /><span className="blue-dash"></span>SF Bay Ferry Access</span>
                    </div>
                    <div className={[`map-icon-link`,((this.state.markerFocus == 'train') ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => this.flyToLocation('train')}><img src={TrainMapMarker} className={`markerMed`} /><span className="blue-dash"></span>12th / 19th St Bart Stations</span>
                    </div>
                    <div className={[`map-icon-link`,((this.state.markerFocus == 'freeway') ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => this.flyToLocation('freeway')}><img src={FreewayMapMarker} className={`markerMed`} /><span className="blue-dash"></span>580/880 Freeway Access</span>
                    </div>     
                    <div className={[`map-icon-link`,((this.state.markerFocus == 'transit') ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => this.flyToLocation('transit')}><img src={TransitMapMarker} className={`markerMed`} /><span className="blue-dash"></span>AC Transit</span>
                    </div>                
                  </div>
                  }

                  {this.state.selectedMapIndex == 1 && this.restaurantFileData.map(marker => (
                    
                    <div className={[`map-icon-link`,((this.state.focusItem == marker.name + marker.position.lat) ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}><span className="blue-dash"></span> {marker.name}</span>
                    </div>
                  ))}

                  {this.state.selectedMapIndex == 2 && this.barFileData.map(marker => (

                    <div className={[`map-icon-link`,((this.state.focusItem == marker.name + marker.position.lat) ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}><span className="blue-dash"></span> {marker.name}</span>
                    </div>
                  ))}

                  {this.state.selectedMapIndex == 3 && this.coffeeFileData.map(marker => (

                    <div className={[`map-icon-link`,((this.state.focusItem == marker.name + marker.position.lat) ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}><span className="blue-dash"></span> {marker.name}</span>
                    </div>
                  ))}

                  {this.state.selectedMapIndex == 4 && this.cultureFileData.map(marker => (

                    <div className={[`map-icon-link`,((this.state.focusItem == marker.name + marker.position.lat) ? 'focusItem' : '')].join(' ')}>
                      <span onClick={() => { this.setState({focusItem: marker.name + marker.position.lat, haltFocusClear: true}); this.flyToLocationCoordinates([marker.position.lng,marker.position.lat])}}><span className="blue-dash"></span> {marker.name}</span>
                    </div>
                  ))}


                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}




