import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'
// import Animated from 'gatsby-plugin-react-native-web'
import DashBar from "prop-types"
import Logo from "../../assets/tt-over-light.svg";
import LogoWhite from "../../assets/tt-over-white.svg";
import YellowRectangle from "../../assets/yellow-rectangle.svg";

import Lottie from 'react-lottie'
import HamburgerDark from '../lottie/hamburger.json'
import HamburgerWhite from '../lottie/hamburger-white.json'

import Flyoutmenu from "./flyout-menu"

import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCurrentState: 'closed',
      logoState: 'white',
      prevLogoState: 'white',
      isPaused: true,
      hamburgerDirection: 1
    }
  }

  static propTypes = {
    siteTitle: PropTypes.string,
    menuState: PropTypes.string,
    logoState: PropTypes.string,
    currentPageName: PropTypes.string
  }

  static defaultProps = {
    siteTitle: ``,
    menuState: `closed`,
    logoState: `white`,
    currentPageName: ``,
    videoBtnOpacity: 1
  }

  componentDidUpdate(prevProps) {
    if (this.props.navState !== prevProps.navState) {
      this.setState({logoState: this.props.navState});
    }

    /*if (this.props.heroProgress !== prevProps.heroProgress) {
      let opacityOffset = +this.props.heroProgress.toFixed(2);
      let opacityValue = Number(1 - opacityOffset);
      //this.setState({videoBtnOpacity: opacityValue })
    }*/
  }

  componentDidMount() {
    this.setState({menuCurrentState: this.props.menuState});
  }

  toggleMenu = () => {
    if(this.state.menuCurrentState == 'closed'){
      this.setState({prevLogoState: this.state.logoState})
      this.setState({menuCurrentState: 'open', logoState: `white`});
      //this.props.scrollLock();
    }else {
      this.setState({menuCurrentState: 'closed', logoState: this.state.prevLogoState});
      //this.props.scrollRestore();
    }
    this.hamburgerAnim();
  }

  hamburgerAnim = () => {
    this.setState({isPaused: false})
    if (this.state.menuCurrentState == 'closed' ){
      this.setState({hamburgerDirection: 1})
    } else {
      this.setState({hamburgerDirection: -1})
    }
  }

  openVideo = () => {
    if (this.state.menuCurrentState == 'open') this.toggleMenu();
    this.props.toggleVideo(); 
  }

  render() {
    const hamburgerDarkOpts = {
      loop: false,
      autoplay: false, 
      animationData: HamburgerDark,
      rendererSettings: {
        //preserveAspectRatio: 'xMidYMid slice'
      }
    };    
    const hamburgerWhiteOpts = {
      loop: false,
      autoplay: false, 
      animationData: HamburgerWhite,
      rendererSettings: {
        //preserveAspectRatio: 'xMidYMid slice'
      }
    };

    let vidBtnClass = '';
    let logoBtnClass = '';
    if (this.props.hideVidBtn) {
      vidBtnClass = 'fade-vid-btn';
      logoBtnClass = 'fade-logo-btn';
    }
    return (
      <div className={`headerWrapper menu-` + this.state.menuCurrentState}>
        <header
          style={{
            background: `none`,
            marginBottom: `1.45rem`,
            position: `fixed`,
            height: `0px`,
            padding: `0px`,
            zIndex: `10`,
            width: '100%',
            overflow: `visible`
          }} className={this.props.currentPageName}
        >
          <AniLink id="logo-link" fade to="/" activeClassName="active" className={'site-logo ' + this.state.logoState + ' ' + logoBtnClass}>
            <Logo className="logo-regular"
              style={{
                zIndex: '9'
              }}
            />
            <LogoWhite className="logo-white"
              style={{
                zIndex: '9'
              }}
            />
          </AniLink>

          <div className={[`video-link-wrapper`, vidBtnClass].join(' ')}>
            <span className={`video-button ` + this.state.logoState } onClick={this.props.toggleVideo} ><YellowRectangle />see oakland rising</span>
          </div>

          <div className={'hamburger-wrapper ' + this.state.logoState + ' ' + this.state.menuCurrentState} >
            <div className="hamburger-icon hamburger-white" onClick={this.toggleMenu}>
              <Lottie options={hamburgerWhiteOpts} height={30} width={30}
              isPaused={this.state.isPaused} direction={this.state.hamburgerDirection}/>
            </div>
            <div className="hamburger-icon hamburger-dark" onClick={this.toggleMenu}>
              <Lottie options={hamburgerDarkOpts} height={30} width={30}
              isPaused={this.state.isPaused}  direction={this.state.hamburgerDirection}/>
            </div>
          </div>
  
        </header>
        <Flyoutmenu menuState={this.state.menuCurrentState} menuUpdate={this.toggleMenu} galleryHandler={this.props.toggleGallery} videoHandler={this.props.toggleVideo}/>
      </div>
    )
  }
}
